
  import {
    defineComponent,
    onMounted,
    ref,
    computed,
    reactive,
    watch,
  } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import moment from 'moment';

  export default defineComponent({
    name: 'ads-per-quarter-reports',
    components: {},
    async setup() {
      const loading = ref(false);
      const showExportBtn = ref(false);
      const data = ref();
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItems = ref<any>({
        adTypeId: '',
        propertyTypesCategoryId: '',
        propertyTypeId: '',
        areaId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS);

      // data.value = store.getters.registeredTradesDashboard;

      const onSubmitCreate = async () => {
        if (selectedItems.value.dateFrom) {
          selectedItems.value.dateFrom = moment(
            selectedItems.value.dateFrom
          ).format('YYYY-MM-DD');
        }
        if (selectedItems.value.dateTo) {
          selectedItems.value.dateTo = moment(
            selectedItems.value.dateTo
          ).format('YYYY-MM-DD');
        }

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        // await store.dispatch(Actions.GET_REGISTERED_TRADE_REPORTS, values);
        // data.value = store.getters.registeredTradesReports;
        await store.dispatch(Actions.GET_ADS_REPORTS, selectedItems);

        data.value = store.getters.adsReports;

        if (data.value.data.length > 0) showExportBtn.value = true;

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_GET_REPORTS_DATA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;

            // router.push({ name: 'ad-types-listing' });
          });
        }
      };

      watch(
        () => selectedItems.value.adTypeId,
        async () => {
          if (selectedItems.value.adTypeId) {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

            await store.dispatch(
              Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID,
              { adType: selectedItems.value.adTypeId }
            );
            setTimeout(() => {
              // Remove page loader after some time
              store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );
            }, 500);
          }
        }
      );

      watch(
        () => selectedItems.value.propertyTypesCategoryId,
        async () => {
          if (selectedItems.value.propertyTypesCategoryId) {
            loading.value = true;

            await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
              type: 'ads',
              propertyTypesCategoryId:
                selectedItems.value.propertyTypesCategoryId,
            });
            loading.value = false;
          }
        }
      );

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('AdsPerQuarterReports'), [
          translate('Reports'),
        ]);
      });
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'isAd',
      });

      const excelHeader = reactive({
        Year: 'year',
        Quarter: 'quarter',
        'Number Of Ads': 'count',
      });

      // const onChangeAdType = async (event) => {
      //
      //   await store.dispatch(
      //     Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID,
      //     { id: event.target.value }
      //   );
      // };
      // const onChangePropertyTypesCategory = async (event) => {
      //   await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
      //     type: 'isAd',
      //     id: event.target.value,
      //   });
      // };

      await store.dispatch(Actions.GET_ALL_AD_TYPES);

      reinitializeComponents();
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        adTypes: computed(() => store.getters.adTypesAllList),
        excelHeader,
        dateNow: new Date().toISOString().slice(0, 10),
        showExportBtn,
        data,
        selectedItems,
        areas: computed(() => store.getters.allListAreas),
        propertyTypesCategories: computed(
          () => store.getters.propertyAllCategoryList
        ),
        propertyTypes: computed(() => store.getters.propertyTypesAllList),
        onSubmitCreate,
        submitButton,
        translate,
        goBack,
        can,
        loading,
      };
    },
  });
